/** @format */

@mixin btnMixin($color) {
  background: $color;
  border: none;
  transition: all .6s;
  color: $app-dark;
  &:hover {
    background: darken($color: $color, $amount: 10);
    transition: all .6s;
  }
  &:focus {
    box-shadow: 0 0 0 0.15rem transparentize($color: $color, $amount: 0.5) !important;
  }
}

@mixin avatar {
  width: calc(30px + (40 - 30) * ((100vw - 375px) / (1440 - 375)));
  height: calc(30px + (40 - 30) * ((100vw - 375px) / (1440 - 375)));
  border-radius: 50%;
}
