/** @format */

.form-control {
  outline: none !important;
  border: none !important;
  &:focus {
    box-shadow: none !important;
    border: none !important;
  }
}

.form-auth {
  background-color: $white;
  border-radius: 5px;
  .form-control {
    background: transparent !important;
    color: $app-dark !important;
    position: relative;
    top: -7px;
  }
  .form-search {
    border: 1px solid $app-dark-25 !important;
    position: relative;
    top: 0 !important;
  }
  .form-border {
    border: 1px solid $app-dark-25 !important;
  }
  .form-modal {
    border: 1px solid $app-dark-25 !important;
    height: 45px;
    &__initial {
      height: initial;
    }
  }
}

.btn-auth {
  border-radius: 50px;
}

.btn {
  box-shadow: none !important;
}

.form-dash {
  .form-control {
    background: $white !important;
    color: $app-dark !important;
    position: relative;
    border-radius: 0;
    border-bottom: 2px solid lighten($color: $app-blue, $amount: 10) !important;
    font-size: 1rem;
    transition: all 0.3s;
    &:focus {
      border-color: $app-blue !important;
      // background: lighten($color: $app-dark-50, $amount: 3) !important;
    }
  }
  .form-icon {
    top: 0;
    z-index: 1;
    // color: $dark-25;
    height: 100%;
    left: 12px;
  }
  .btn {
    font-size: 0.9rem;
  }
}

.color-box {
  height: calc(36px + (45 - 36) * ((100vw - 375px) / (1440 - 375)));
}

.table--header {
  height: 40px;
  .form-control {
    height: 40px !important;
  }
}

.form-select,
.form-select:focus {
  background-color: $app-dark;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  // -webkit-text-fill-color: $white !important;
  // caret-color: $white;
}

.img-block {
  height: calc(130px + (180 - 130) * ((100vw - 375px) / (1440 - 375)));
  img {
    height: inherit;
  }
}
