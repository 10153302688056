/** @format */

$white: #fff;
// $app-dark: #151515;
// $app-dark-50: #1a1a1a;
// $app-dark-25: #252525;
$app-dark: #232331;
$app-dark-50: #2c2e41;
$app-dark-25: #7c7e8a;
$app-blue: #1877f2;
$app-green: #51e0b0;
$app-red: #f9548b;
$app-trans: #fafafa1a;
