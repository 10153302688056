.btn-blue {
  color: #232331;
  background: #1877f2;
  border: none;
  transition: all .6s;
}

.btn-blue:hover {
  background: #0b5fcc;
  transition: all .6s;
}

.btn-blue:focus {
  box-shadow: 0 0 0 .15rem #1877f280 !important;
}

.btn-green {
  color: #232331;
  background: #51e0b0;
  border: none;
  transition: all .6s;
}

.btn-green:hover {
  background: #26d89c;
  transition: all .6s;
}

.btn-green:focus {
  box-shadow: 0 0 0 .15rem #51e0b080 !important;
}

.btn-red {
  color: #232331;
  background: #f9548b;
  border: none;
  transition: all .6s;
}

.btn-red:hover {
  background: #f7236a;
  transition: all .6s;
}

.btn-red:focus {
  box-shadow: 0 0 0 .15rem #f9548b80 !important;
}

.app-dark {
  background-color: #232331;
}

.app-dark-50 {
  background-color: #2c2e41;
}

.app-dark-25 {
  background-color: #7c7e8a;
}

.app-green {
  background-color: #51e0b0;
}

.app-blue {
  background-color: #1877f2;
}

.app-trans {
  background-color: #fafafa1a;
}

.app-red {
  background-color: #f9548b;
}

.app-warning {
  background-color: #ff0;
}

.text-dark-50 {
  color: #2c2e41;
}

.text-dark-25 {
  color: #7c7e8a;
}

.text-blue {
  color: #1877f2 !important;
}

.text-green {
  color: #51e0b0;
}

.text-red {
  color: #f9548b;
}

.text-transparent {
  color: #fafafa1a;
}

.text-light {
  color: #a39e9e !important;
}

.link-green, .link-green:hover {
  color: #51e0b0;
}

.x-small {
  font-size: .75em !important;
}

.form-control {
  border: none !important;
  outline: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
}

.form-auth {
  background-color: #fff;
  border-radius: 5px;
}

.form-auth .form-control {
  position: relative;
  top: -7px;
  color: #232331 !important;
  background: none !important;
}

.form-auth .form-search {
  position: relative;
  border: 1px solid #7c7e8a !important;
  top: 0 !important;
}

.form-auth .form-border {
  border: 1px solid #7c7e8a !important;
}

.form-auth .form-modal {
  height: 45px;
  border: 1px solid #7c7e8a !important;
}

.form-auth .form-modal__initial {
  height: initial;
}

.btn-auth {
  border-radius: 50px;
}

.btn {
  box-shadow: none !important;
}

.form-dash .form-control {
  border-radius: 0;
  font-size: 1rem;
  transition: all .3s;
  position: relative;
  color: #232331 !important;
  background: #fff !important;
  border-bottom: 2px solid #4893f5 !important;
}

.form-dash .form-control:focus {
  border-color: #1877f2 !important;
}

.form-dash .form-icon {
  z-index: 1;
  height: 100%;
  top: 0;
  left: 12px;
}

.form-dash .btn {
  font-size: .9rem;
}

.color-box {
  height: calc(.84507vw + 32.831px);
}

.table--header {
  height: 40px;
}

.table--header .form-control {
  height: 40px !important;
}

.form-select, .form-select:focus {
  background-color: #232331;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 5000s;
}

.img-block {
  height: calc(4.69484vw + 112.394px);
}

.img-block img {
  height: inherit;
}

.table-container {
  overflow-x: auto;
}

.table {
  font-size: .85rem;
}

.table thead tr, .table tbody tr {
  border-top-left-radius: 5px;
}

.table thead tr td, .table tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.table tbody tr:last-child {
  border-bottom-left-radius: 10px !important;
}

.dropdown-item:active, .dropdown-item:focus {
  outline: none !important;
}

/*# sourceMappingURL=AuditTrails.7b68aba5.css.map */
