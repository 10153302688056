/** @format */

// /** @format */

.btn-blue {
  @include btnMixin($app-blue);
}

.btn-green {
  @include btnMixin($app-green);
}

.btn-red {
  @include btnMixin($app-red);
}

.app {
  &-dark {
    background-color: $app-dark;
    &-50 {
      background-color: $app-dark-50;
    }
    &-25 {
      background-color: $app-dark-25;
    }
  }
  &-green {
    background-color: $app-green;
  }
  &-blue {
    background-color: $app-blue;
  }
  &-trans {
    background-color: $app-trans;
  }
  &-red {
    background-color: $app-red;
  }
  &-warning {
    background-color: yellow;
  }
}

.text {
  &-dark {
    &-50 {
      color: $app-dark-50;
    }
    &-25 {
      color: $app-dark-25;
    }
  }
  &-blue {
    color: $app-blue !important;
  }
  &-green {
    color: $app-green;
  }
  &-red {
    color: $app-red;
  }
  &-transparent {
    color: $app-trans;
  }
  &-light {
    color: #a39e9e !important;
  }
}

.link {
  &-green,
  &-green:hover {
    color: $app-green;
  }
}

// Fonts
.x-small {
  font-size: 0.75em !important;
}
