/** @format */

@import "../partials/partials";

.table-container {
  overflow-x: auto;
  // border: 1px solid $app-dark-25;
}

.table {
  font-size: 0.85rem;
  thead tr,
  tbody tr {
    border-top-left-radius: 5px;
    // border-bottom: 1px solid $app-dark-25 !important;
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  tbody tr {
    &:nth-child(even) {
      // background: $app-dark-50;
    }
    &:last-child {
      border-bottom-left-radius: 10px !important;
    }
  }
}

.dropdown-item {
  &:active,
  &:focus {
    // background: $light-blue !important;
    outline: none !important;
  }
}
